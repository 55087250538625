.tail-select .select-label:after,
.tail-select-container .tail-select-handle:after {
	top: 0;
	margin: 0;
	padding: 0;
	z-index: 21;
	display: inline-block;
	content: "";
	opacity: 0.75;
	position: absolute;
	vertical-align: top;
	background-repeat: no-repeat;
	background-position: center center;
}

.tail-select,
.tail-select *,
.tail-select *:after,
.tail-select *:before {
	box-sizing: border-box;
}

.tail-select {
	width: 100%;
	padding: 0;
	display: inline-block;
	position: relative;
}

.tail-select mark {
	color: #fff;
	background-color: #DC4650;
}

.tail-select button.all,
.tail-select button.none {
	color: #707478;
	cursor: pointer;
	margin: 1px 3px 0 0;
	padding: 1px 5px;
	display: inline-block;
	font-size: 12px;
	text-shadow: none;
	line-height: 18px;
	vertical-align: top;
	background-color: transparent;
	outline: none;
	border: 1px solid $colorBorderControl;
}

.tail-select button.all:hover {
	color: #3C82E6;
	border-color: #3C82E6;
	background-color: transparent;
}

.tail-select button.none:hover {
	color: #DC4650;
	border-color: #DC4650;
	background-color: transparent;
}

.select-label {
	cursor: pointer;
	width: 100%;
	//max-width: 120px;
	height: $sizeControlHeight;
	margin: 0;
	padding: 0 $paddingControlHorizontal;
	display: block;
	z-index: 20;
	margin-bottom: -3px;
	position: relative;
	text-align: left;
	line-height: $sizeControlHeight - 1;
	background-color: $colorBg;
	border: 1px solid $colorBorderControl;
	border-radius: $sizeControlBorderRadius;

	&.unselected {
		color: $colorComplementary;
	}

	.label-inner {
		display: inline-block;
		@include truncateLine(95%);
	}
}

.select-label:hover,
.select-label:focus {
	border-bottom-color: $colorBase;
}

.tail-select.-invalid .select-label {
	border-bottom-color: $colorError;
}

.tail-select.active .select-label {
	z-index: 25;
	border-radius: 4px 4px 0 0;
}

.tail-select .select-label:after {
	content: "";
	height: 0;
	width: 0;
	border-style: solid;
	border-color: $colorBase transparent transparent transparent;
	border-width: 4px 4px 0 4px;
	position: absolute;
	right: 14px;
	top: 50%;
	margin-top: -2px;
	pointer-events: none;

	@include transition;
}

.tail-select.active .select-label:after {
	transform: rotate(180deg);
}

.tail-select .select-label .label-count {
	color: #707478;
	margin: 0 5px 0 0;
	padding: 0 7px 0 0;
	display: inline-block;
	border-right: 1px solid #d0d0d0;
}

.tail-select-container {
	width: auto;
	min-height: 34px;
	margin: 5px 0;
	padding: 2px;
	display: block;
	text-align: left;
	background-color: #ffffff;
	border: 1px solid $colorBorderControl;
}

.tail-select-container .tail-select-handle {
	width: auto;
	color: #fff;
	cursor: pointer;
	margin: 2px;
	padding: 2px 24px 2px 5px;
	display: inline-block;
	position: relative;
	font-size: 12px;
	text-align: left;
	line-height: 20px;
	background-color: #3C82E6;
	transition: opacity 50ms linear;
}

.tail-select-container .tail-select-handle:hover {
	opacity: 0.75;
}

.tail-select-container .tail-select-handle:after {
	width: 24px;
	height: 25px;
	opacity: 0.3;
	background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC\ 9zdmciIHdpZHRoPSIxMiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDEyIDE2Ij48cGF0aCBmaWxsPSIjZmZmZmZmIiBkP\ SJNNy40OCA4bDMuNzUgMy43NS0xLjQ4IDEuNDhMNiA5LjQ4bC0zLjc1IDMuNzUtMS40OC0xLjQ4TDQuNTIgOCAuNzcgNC4y\ NWwxLjQ4LTEuNDhMNiA2LjUybDMuNzUtMy43NSAxLjQ4IDEuNDhMNy40OCA4eiIvPjwvc3ZnPg==");
	transition: opacity 50ms linear;
}

.tail-select-container .tail-select-handle:hover:after {
	opacity: 1;
}

.tail-select .select-dropdown {
	width: 100%;
	margin: 2px 0 0;
	padding: 0;
	display: none;
	overflow: hidden;
	position: absolute;
	z-index: 55;
	font-size: 1rem;
	text-align: left;
	line-height: 22px;
	background-color: $colorBg;
	box-shadow: 0 5px 15px -7px rgba(0, 0, 0, .5);
}

.tail-select.open-top .select-dropdown {
	bottom: 35px;
	margin: 0 0 -3px;
}

.tail-select.active .select-dropdown {
	display: block;
}

.tail-select .select-dropdown .dropdown-search {
	width: 100%;
	margin: 0;
	padding: 12px;
	display: block;
	z-index: 31;
	position: relative;
}

.tail-select .select-dropdown .dropdown-search input {
	width: 100%;
	margin: 0;
	padding: 4px 12px;
	display: block;
	font-size: rem(11);
	color: $colorComplementary;
	text-align: left;
	line-height: 20px;
	outline: none;
	background: none;
	background-color: transparent;
	border-bottom: 1px solid;
	border-bottom-color: $colorBorderControl;
}

.tail-select .select-dropdown .dropdown-inner {
	width: 100%;
	margin: 0;
	padding: 0;
	display: block;
	overflow-x: hidden;
	overflow-y: auto;
	//border: 1px solid $colorBorder;
	//border-top-color: $colorBase;
	border-radius: 0 0 $sizeControlBorderRadius $sizeControlBorderRadius;
	@extend %scrollbar;
}

.tail-select .select-dropdown ul,
.tail-select .select-dropdown ul li {
	width: auto;
	margin: 0;
	padding: 0;
	display: block;
	list-style: none;
}

.tail-select .select-dropdown ul li {
	padding: 9px $paddingControlHorizontal;
	text-align: left;
	line-height: 1.4;
	display: flex;
	align-items: center;
	justify-content: space-between;

	&:empty {
		display: none;
	}
}

.tail-select .select-dropdown ul li .option-description {
	color: $colorTextSecondary;
	width: auto;
	margin: 0;
	padding: 0;
	display: block;
	font-size: rem(11);
	text-align: left;
	line-height: 14px;
}

.tail-select .select-dropdown ul li li {
	padding-left: 30px;
}

.tail-select .select-dropdown ul li.dropdown-action {
	top: 0;
	left: 0;
	width: 100%;
	height: 35px;
	margin: -10px 0 5px;
	padding: 5px 0 0;
	z-index: 30;
	position: static;
	text-align: center;
	background-color: #ffffff;
	border-bottom: 1px solid $colorBorderControl;
}

.tail-select .select-dropdown ul li.dropdown-action button {
	margin: 0 5px;
	padding: 2px 5px;
	font-size: 12px;
	line-height: 18px;
}

.tail-select .select-dropdown ul li.optgroup-title {
	font-size: 14px;
}

.tail-select .select-dropdown ul li.optgroup-title button {
	float: right;
	font-size: 10px;
	line-height: 14px;
}

.tail-select .select-dropdown ul li.dropdown-empty {
	cursor: auto;
	text-align: center;
	background-color: #fff;
	line-height: auto;
}

.tail-select .select-dropdown ul li.dropdown-option {
	cursor: pointer;
	position: relative;
	@include transition;
}

.tail-select .select-dropdown ul li.dropdown-option:hover {
	background-color: #f0f0f0;
}

.tail-select .select-dropdown ul li.dropdown-option.selected {
	background-color: #f0f0f0;
}

.tail-select .select-dropdown ul li.dropdown-option.disabled {
	color: lighten($colorTextSecondary, 10%);

	.option-description {
		color: lighten($colorTextSecondary, 10%);
	}
}

.tail-select.disabled {
	pointer-events: none;

	&:focus,
	&:hover {
		border-color: $colorBorderControl;
	}

	.select-label {
		background: $colorBgSecondary;
	}
}

.tail-select .select-dropdown ul li.dropdown-option.disabled:hover {
	background-color: transparent;
	cursor: default;
}

.tail-select .select-dropdown ul li.dropdown-option:hover:before {
	opacity: 0.5;
}

.tail-select .select-dropdown ul li.dropdown-option.selected:before {
	opacity: 0.75;
}

.tail-select.deselect .select-dropdown ul li.dropdown-option.selected:hover:before {
	opacity: 0.75;
	background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC\ 9zdmciIHdpZHRoPSIxMiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDEyIDE2Ij48cGF0aCBkPSJNNy40OCA4bDMuNzUgM\ y43NS0xLjQ4IDEuNDhMNiA5LjQ4bC0zLjc1IDMuNzUtMS40OC0xLjQ4TDQuNTIgOCAuNzcgNC4yNWwxLjQ4LTEuNDhMNiA2\ LjUybDMuNzUtMy43NSAxLjQ4IDEuNDhMNy40OCA4eiIvPjwvc3ZnPg==");
}

.tail-select .select-dropdown ul li.dropdown-option.disabled:before {
	opacity: 0.75;
	background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC\ 9zdmciIHdpZHRoPSIxNCIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDE0IDE2Ij48cGF0aCBkPSJNNyAxQzMuMTQgMSAwI\ DQuMTQgMCA4czMuMTQgNyA3IDcgNy0zLjE0IDctNy0zLjE0LTctNy03em0wIDEuM2MxLjMgMCAyLjUuNDQgMy40NyAxLjE3\ bC04IDhBNS43NTUgNS43NTUgMCAwIDEgMS4zIDhjMC0zLjE0IDIuNTYtNS43IDUuNy01Ljd6bTAgMTEuNDFjLTEuMyAwLTI\ uNS0uNDQtMy40Ny0xLjE3bDgtOGMuNzMuOTcgMS4xNyAyLjE3IDEuMTcgMy40NyAwIDMuMTQtMi41NiA1LjctNS43IDUuN3\ oiLz48L3N2Zz4=");
}

.tail-select.multiple .select-dropdown ul li.dropdown-option:before {
	opacity: 0;
	background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC\ 9zdmciIHdpZHRoPSIxNCIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDE0IDE2Ij48cGF0aCBkPSJNMTMgMUgxYy0uNTUgM\ C0xIC40NS0xIDF2MTJjMCAuNTUuNDUgMSAxIDFoMTJjLjU1IDAgMS0uNDUgMS0xVjJjMC0uNTUtLjQ1LTEtMS0xem0wIDEz\ SDFWMmgxMnYxMnpNNiA5SDNWN2gzVjRoMnYzaDN2Mkg4djNINlY5eiIvPjwvc3ZnPg==");
}

.tail-select.multiple .select-dropdown ul li.dropdown-option:hover:before {
	opacity: 0.5;
}

.tail-select.multiple .select-dropdown ul li.dropdown-option.selected:before {
	opacity: 0.75;
	background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC\ 9zdmciIHdpZHRoPSIxNCIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDE0IDE2Ij48cGF0aCBkPSJNMTMgMUgxYy0uNTUgM\ C0xIC40NS0xIDF2MTJjMCAuNTUuNDUgMSAxIDFoMTJjLjU1IDAgMS0uNDUgMS0xVjJjMC0uNTUtLjQ1LTEtMS0xem0wIDEz\ SDFWMmgxMnYxMnpNNCA4YzAtMS42NiAxLjM0LTMgMy0zczMgMS4zNCAzIDMtMS4zNCAzLTMgMy0zLTEuMzQtMy0zeiIvPjw\ vc3ZnPg==");
}

.tail-select.multiple .select-dropdown ul li.dropdown-option.selected:hover:before {
	opacity: 0.75;
	background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC\ 9zdmciIHdpZHRoPSIxNCIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDE0IDE2Ij48cGF0aCBkPSJNMTMgMUgxYy0uNTUgM\ C0xIC40NS0xIDF2MTJjMCAuNTUuNDUgMSAxIDFoMTJjLjU1IDAgMS0uNDUgMS0xVjJjMC0uNTUtLjQ1LTEtMS0xem0wIDEz\ SDFWMmgxMnYxMnptLTItNUgzVjdoOHYyeiIvPjwvc3ZnPg==");
}

.tail-select.multiple .select-dropdown ul li.dropdown-option.disabled:before {
	opacity: 0.75;
	background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC\ 9zdmciIHdpZHRoPSIxNCIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDE0IDE2Ij48cGF0aCBkPSJNMTMgMUgxYy0uNTUgM\ C0xIC40NS0xIDF2MTJjMCAuNTUuNDUgMSAxIDFoMTJjLjU1IDAgMS0uNDUgMS0xVjJjMC0uNTUtLjQ1LTEtMS0xem0wIDEz\ SDFWMmgxMnYxMnptLTguNS0ySDN2LTEuNUw5LjUgNEgxMXYxLjVMNC41IDEyeiIvPjwvc3ZnPg==");
}

.tail-select.hide-disabled .select-dropdown ul li.dropdown-option.disabled,
.tail-select.hide-selected .select-dropdown ul li.dropdown-option.selected,
.tail-select.hide-selected .select-dropdown ul li.dropdown-option[data-key=''] {
	display: none !important;
}

.dropdown-fixed-row {
	height: $sizeControlHeight;
	line-height: $sizeControlHeight - 1;
	padding: 0 $paddingControlHorizontal;
	@include transition;

	span {
		pointer-events: none;
		display: inline-block;
	}

	&:hover {
		cursor: pointer;
	}
}
