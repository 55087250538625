/* Runtime */
@import "./tokens";
@import "./sprite";

/* Core */
@import "~@lfg/fabrics/scss/core/functions";
@import "~@lfg/fabrics/scss/core/mixins";
@import "~@lfg/fabrics/scss/core/placeholders";
@import "~@lfg/fabrics/scss/core/font";
@import "~@lfg/fabrics/scss/core/base";
@import "~@lfg/fabrics/scss/core/layout";
@import "~@lfg/fabrics/scss/core/utilites";
@import "~@lfg/fabrics/scss/core/animations";

/* Components */
@import "~@lfg/fabrics/scss/components/collapse";
@import "~@lfg/fabrics/scss/components/amount";
@import "~@lfg/fabrics/scss/components/arrow";
@import "~@lfg/fabrics/scss/components/autocomplete";
@import "~@lfg/fabrics/scss/components/badge";
@import "~@lfg/fabrics/scss/components/banner";
@import "~@lfg/fabrics/scss/components/breadcrumbs";
@import "~@lfg/fabrics/scss/components/burger";
@import "~@lfg/fabrics/scss/components/button";
@import "~@lfg/fabrics/scss/components/checkbox";
@import "~@lfg/fabrics/scss/components/divider";
@import "~@lfg/fabrics/scss/components/dropdown";
@import "~@lfg/fabrics/scss/components/dropdownMenu";
@import "~@lfg/fabrics/scss/components/feature";
@import "~@lfg/fabrics/scss/components/filters";
@import "~@lfg/fabrics/scss/components/form";
@import "~@lfg/fabrics/scss/components/heading";
@import "~@lfg/fabrics/scss/components/icon";
@import "~@lfg/fabrics/scss/components/iconGroup";
@import "~@lfg/fabrics/scss/components/image";
@import "~@lfg/fabrics/scss/components/info";
@import "~@lfg/fabrics/scss/components/indicator";
@import "~@lfg/fabrics/scss/components/input";
@import "~@lfg/fabrics/scss/components/instaCard";
@import "~@lfg/fabrics/scss/components/kebab";
@import "~@lfg/fabrics/scss/components/label";
@import "~@lfg/fabrics/scss/components/list";
@import "~@lfg/fabrics/scss/components/loader";
@import "~@lfg/fabrics/scss/components/map";
@import "~@lfg/fabrics/scss/components/modal";
@import "~@lfg/fabrics/scss/components/nav";
@import "~@lfg/fabrics/scss/components/navMain";
@import "~@lfg/fabrics/scss/components/overlay";
@import "~@lfg/fabrics/scss/components/pagination";
@import "~@lfg/fabrics/scss/components/spriteIcon";
@import "~@lfg/fabrics/scss/components/placeholder";
@import "~@lfg/fabrics/scss/components/plus";
@import "~@lfg/fabrics/scss/components/productCard";
@import "~@lfg/fabrics/scss/components/productCardWide";
@import "~@lfg/fabrics/scss/components/radio";
@import "~@lfg/fabrics/scss/components/radioButton";
@import "~@lfg/fabrics/scss/components/radioGroupCard";
@import "~@lfg/fabrics/scss/components/radioPill";
@import "~@lfg/fabrics/scss/components/select";
@import "~@lfg/fabrics/scss/components/sideButton";
@import "~@lfg/fabrics/scss/components/slider";
@import "~@lfg/fabrics/scss/components/spec";
@import "~@lfg/fabrics/scss/components/spinner";
@import "~@lfg/fabrics/scss/components/stepper";
@import "~@lfg/fabrics/scss/components/swatch";
@import "~@lfg/fabrics/scss/components/switcher";
@import "~@lfg/fabrics/scss/components/table";
@import "~@lfg/fabrics/scss/components/tabs";
@import "~@lfg/fabrics/scss/components/textarea";
@import "~@lfg/fabrics/scss/components/topBar";
@import "~@lfg/fabrics/scss/components/urlOverlay";
@import "~@lfg/fabrics/scss/components/video";
@import "~@lfg/fabrics/scss/components/well";
@import "~@lfg/fabrics/scss/components/boutiques";
@import "~@lfg/fabrics/scss/components/size-table";
@import "~@lfg/fabrics/scss/components/sets";

/* Vendor */
@import "./vendor/tail.select";

/* Application components */
@import "./components/recaptcha";

.phone-icon {
  position: absolute;
  right: 16px;
  top: 0;
  bottom: 0;
  align-items: center;
  cursor: pointer;

  &:hover {
    .phone-icon__phone {
      display: flex;
      opacity: 1;
    }
  }

  &_desktop {
    display: none;
  }

  &_mobile {
    display: flex;
  }

  &__svg {
    cursor: pointer;
  }

  &__phone {
    display: none;
    position: absolute;
    top: 85%;
    right: -68px;
    background-color: #fff;
    z-index: 1;
    padding: 10px 20px;
    box-shadow: 0 0 5px 4px #00000021;
    cursor: default;
    opacity: 0;
    transition: opacity 0.3s;

    &::before {
      content: '';
      width: 0;
      height: 0;
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      border-bottom: 10px solid #fff;
      position: absolute;
      top: -9px;
      left: 70px;
    }
  }
}

/* Выподающий список главного поиска */
.js-searchAutocomplete .m-autocomplete__item,
.js-searchAutocomplete .m-autocomplete__list {
  padding: 5px;
}

@media (min-width: 1280px) {
  /* Выподающий список главного поиска */
  .search-modal__body {
    overflow: visible;
  }

  .phone-icon {
    &_desktop {
      display: flex;
    }

    &_mobile {
      display: none;
    }
  }

  .c-map__layer {
    min-height: 500px;
  }
}

.c-input.-disabled {
  background-color: #f1f1f1;
}

.u-textMuted {
  .c-plus {
    &::before, &::after {
      background-color: $colorTextSecondary;
    }
  }
}

.c-dolyame {
  width: 168px;
  height: 24px;
}
