// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$mc-name: 'mc';
$mc-x: 42px;
$mc-y: 20px;
$mc-offset-x: -42px;
$mc-offset-y: -20px;
$mc-width: 26px;
$mc-height: 20px;
$mc-total-width: 73px;
$mc-total-height: 60px;
$mc-image: '/assets/img/sprite.png';
$mc: (42px, 20px, -42px, -20px, 26px, 20px, 73px, 60px, '/assets/img/sprite.png', 'mc', );
$mir-name: 'mir';
$mir-x: 0px;
$mir-y: 20px;
$mir-offset-x: 0px;
$mir-offset-y: -20px;
$mir-width: 42px;
$mir-height: 20px;
$mir-total-width: 73px;
$mir-total-height: 60px;
$mir-image: '/assets/img/sprite.png';
$mir: (0px, 20px, 0px, -20px, 42px, 20px, 73px, 60px, '/assets/img/sprite.png', 'mir', );
$paypal-name: 'paypal';
$paypal-x: 0px;
$paypal-y: 0px;
$paypal-offset-x: 0px;
$paypal-offset-y: 0px;
$paypal-width: 73px;
$paypal-height: 20px;
$paypal-total-width: 73px;
$paypal-total-height: 60px;
$paypal-image: '/assets/img/sprite.png';
$paypal: (0px, 0px, 0px, 0px, 73px, 20px, 73px, 60px, '/assets/img/sprite.png', 'paypal', );
$visa-name: 'visa';
$visa-x: 0px;
$visa-y: 40px;
$visa-offset-x: 0px;
$visa-offset-y: -40px;
$visa-width: 42px;
$visa-height: 20px;
$visa-total-width: 73px;
$visa-total-height: 60px;
$visa-image: '/assets/img/sprite.png';
$visa: (0px, 40px, 0px, -40px, 42px, 20px, 73px, 60px, '/assets/img/sprite.png', 'visa', );
$retina-mc-name: 'retina_mc';
$retina-mc-x: 84px;
$retina-mc-y: 40px;
$retina-mc-offset-x: -84px;
$retina-mc-offset-y: -40px;
$retina-mc-width: 52px;
$retina-mc-height: 40px;
$retina-mc-total-width: 146px;
$retina-mc-total-height: 120px;
$retina-mc-image: '/assets/img/sprite@2x.png';
$retina-mc: (84px, 40px, -84px, -40px, 52px, 40px, 146px, 120px, '/assets/img/sprite@2x.png', 'retina_mc', );
$retina-mir-name: 'retina_mir';
$retina-mir-x: 0px;
$retina-mir-y: 40px;
$retina-mir-offset-x: 0px;
$retina-mir-offset-y: -40px;
$retina-mir-width: 84px;
$retina-mir-height: 40px;
$retina-mir-total-width: 146px;
$retina-mir-total-height: 120px;
$retina-mir-image: '/assets/img/sprite@2x.png';
$retina-mir: (0px, 40px, 0px, -40px, 84px, 40px, 146px, 120px, '/assets/img/sprite@2x.png', 'retina_mir', );
$retina-paypal-name: 'retina_paypal';
$retina-paypal-x: 0px;
$retina-paypal-y: 0px;
$retina-paypal-offset-x: 0px;
$retina-paypal-offset-y: 0px;
$retina-paypal-width: 146px;
$retina-paypal-height: 40px;
$retina-paypal-total-width: 146px;
$retina-paypal-total-height: 120px;
$retina-paypal-image: '/assets/img/sprite@2x.png';
$retina-paypal: (0px, 0px, 0px, 0px, 146px, 40px, 146px, 120px, '/assets/img/sprite@2x.png', 'retina_paypal', );
$retina-visa-name: 'retina_visa';
$retina-visa-x: 0px;
$retina-visa-y: 80px;
$retina-visa-offset-x: 0px;
$retina-visa-offset-y: -80px;
$retina-visa-width: 84px;
$retina-visa-height: 40px;
$retina-visa-total-width: 146px;
$retina-visa-total-height: 120px;
$retina-visa-image: '/assets/img/sprite@2x.png';
$retina-visa: (0px, 80px, 0px, -80px, 84px, 40px, 146px, 120px, '/assets/img/sprite@2x.png', 'retina_visa', );
$spritesheet-width: 73px;
$spritesheet-height: 60px;
$spritesheet-image: '/assets/img/sprite.png';
$spritesheet-sprites: ($mc, $mir, $paypal, $visa, );
$spritesheet: (73px, 60px, '/assets/img/sprite.png', $spritesheet-sprites, );
$retina-spritesheet-width: 146px;
$retina-spritesheet-height: 120px;
$retina-spritesheet-image: '/assets/img/sprite@2x.png';
$retina-spritesheet-sprites: ($retina-mc, $retina-mir, $retina-paypal, $retina-visa, );
$retina-spritesheet: (146px, 120px, '/assets/img/sprite@2x.png', $retina-spritesheet-sprites, );

// These "retina group" variables are mappings for the naming and pairing of normal and retina sprites.
//
// The list formatted variables are intended for mixins like `retina-sprite` and `retina-sprites`.
$mc-group-name: 'mc';
$mc-group: ('mc', $mc, $retina-mc, );
$mir-group-name: 'mir';
$mir-group: ('mir', $mir, $retina-mir, );
$paypal-group-name: 'paypal';
$paypal-group: ('paypal', $paypal, $retina-paypal, );
$visa-group-name: 'visa';
$visa-group: ('visa', $visa, $retina-visa, );
$retina-groups: ($mc-group, $mir-group, $paypal-group, $visa-group, );

// The provided mixins are intended to be used with the array-like variables
//
// .icon-home {
//   @include sprite-width($icon-home);
// }
//
// .icon-email {
//   @include sprite($icon-email);
// }
//
// Example usage in HTML:
//
// `display: block` sprite:
// <div class="icon-home"></div>
//
// To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:
//
// // CSS
// .icon {
//   display: inline-block;
// }
//
// // HTML
// <i class="icon icon-home"></i>
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

// The `retina-sprite` mixin sets up rules and a media query for a sprite/retina sprite.
//   It should be used with a "retina group" variable.
//
// The media query is from CSS Tricks: https://css-tricks.com/snippets/css/retina-display-media-query/
//
// $icon-home-group: ('icon-home', $icon-home, $icon-home-2x, );
//
// .icon-home {
//   @include retina-sprite($icon-home-group);
// }
@mixin sprite-background-size($sprite) {
  $sprite-total-width: nth($sprite, 7);
  $sprite-total-height: nth($sprite, 8);
  background-size: $sprite-total-width $sprite-total-height;
}

@mixin retina-sprite($retina-group) {
  $normal-sprite: nth($retina-group, 2);
  $retina-sprite: nth($retina-group, 3);
  @include sprite($normal-sprite);

  @media (-webkit-min-device-pixel-ratio: 2),
         (min-resolution: 192dpi) {
    @include sprite-image($retina-sprite);
    @include sprite-background-size($normal-sprite);
  }
}

// The `sprites` mixin generates identical output to the CSS template
//   but can be overridden inside of SCSS
//
// @include sprites($spritesheet-sprites);
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}

// The `retina-sprites` mixin generates a CSS rule and media query for retina groups
//   This yields the same output as CSS retina template but can be overridden in SCSS
//
// @include retina-sprites($retina-groups);
@mixin retina-sprites($retina-groups) {
  @each $retina-group in $retina-groups {
    $sprite-name: nth($retina-group, 1);
    .#{$sprite-name} {
      @include retina-sprite($retina-group);
    }
  }
}
